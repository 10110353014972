.mainContainer {
  width: 100%;
  height: 100%;
  display: flex;
  background-image: linear-gradient(to bottom, #111111, #333333, #111111);
}

.leftContainer {
  color: white;
  display: flex;
  justify-content: center;
  width: 20%;
  height: 100%;
  background-image: linear-gradient(to bottom, #050505, #222222, #050505);
}

.middleContainer {
  color: white;
  display: block;
  justify-content: center;
  width: 60%;
  height: 100%;
}

.rightContainer {
  color: white;
  display: flex;
  justify-content: center;
  width: 20%;
  height: 100%;
  background-image: linear-gradient(to bottom, #050505, #222222, #050505);
}

@media only screen and (max-width: 1100px) {
  .rightContainer {
    display: none;
  }
}

.chartContainer {
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.rssFeed {
  height: 1500px;
  width: 300px;
}
