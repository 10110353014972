.mainContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cardContainer {
  color: rgb(189, 189, 189);
  opacity: 95%;
  display: block;
  width: 250px;
  height: 250px;
  margin: 20px;
  border-radius: 20px;
  padding: 10px;
  background-image: linear-gradient(to right, #111111, #222222);
  border-style: solid;
  border-width: 5px;
}

.cardContainer:hover {
  color: white;
  opacity: 100%;
  scale: 1.1;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .cardContainer {
    opacity: 95%;
    display: block;
    width: 200px;
    height: 200px;
    margin: 20px;
    border-radius: 20px;
    padding: 10px;
    background-image: linear-gradient(to right, #111111, #222222);
    border-style: solid;
    border-width: 5px;
  }
}

.image {
  max-width: 100%;
  height: auto;
  object-fit: contain;

  border-radius: 20px;
}

.title {
  font-size: 14px;
  margin-top: 20px;
  color: white;
  height: 50px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
  margin-top: 5px;
  border-radius: 5px;
  background-image: linear-gradient(to right, #9f0101, #9b0f0f, #9f0101);
}

@media only screen and (max-width: 600px) {
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10px;
    margin-top: -3px;
    border-radius: 5px;
    background-image: linear-gradient(to right, #9f0101, #9b0f0f, #9f0101);
  }
}
