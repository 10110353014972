.mainContainer {
  height: 100px;
  background-color: black;

  padding-left: 5%;
  padding-right: 5%;
  display: flex;
}
.leftContainer {
  width: 30%;
  display: flex;
  justify-content: left;
  align-items: center;
}

.centerContainer {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .centerContainer {
    width: 40%;
    display: none;
    justify-content: center;
    align-items: center;
  }
}

.rightContainer {
  width: 30%;
  display: flex;
  justify-content: end;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .rightContainer {
    width: 60%;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}

.logo {
  scale: 0.65;
}

.logo2 {
  max-height: 50px;
  width: auto;
}

.logoTitle {
  color: white;
  font-size: 30px;
  font-family: "Irish Grover", Helvetica;
}

.icon {
  height: 26px;
  position: relative;
  width: 26px;
}

.menuText {
  color: lightgray;
  opacity: 50%;
  font-family: "Lato";
  font-weight: 300;
  background-image: none;
  font-size: 20px;
  margin-left: 30px;
  font-weight: 400;
  letter-spacing: 1;
}

.menuText:hover {
  opacity: 100%;
  color: white;
  text-decoration: underline;
  text-underline-offset: 8px;
}

.buycoin {
  color: #ffffff;
  font-family: "Familjen Grotesk", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.buttonoutline {
  align-items: center;
  background-image: linear-gradient(to right, #93012f, #af0707, #93012f);
  border-radius: 30px;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 18px 32px;
  position: relative;
  height: 30px;
  width: 187px;
}
