.mainContainer {
  max-width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  background-image: linear-gradient(to bottom, #111111, #252525, #111111);
}
.header {
  color: white;
  font-size: 30px;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
}
.cardContainer {
  color: white;
  font-size: 20px;
  font-weight: 900;
  font-family: "Lato";
  border-color: rgb(51, 50, 50);
  display: block;
  width: 280px;
  height: 300px;
  background-image: linear-gradient(to bottom, #222222, #444444, #111111);
  margin: 10px;
  border-radius: 20px;
  border-style: solid;
  border-width: 3px;
  transition: transform 0.7s ease;
}
.cardContainer:hover {
  border-color: rgb(178, 221, 236);
  transform: scale(1.07);
  cursor: pointer;
}

.bannerImage {
  width: 274px;
  max-height: 200px;
  object-fit: fill;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
.titleContainer {
  width: 100%;
  height: 40px;
  padding-left: 20px;
  display: flex;
  align-items: center;
}
.title {
  color: white;
  font-size: 18px;
  font-family: "Lato";
  font-weight: 900;
}

.subTitle {
  color: gray;
  font-size: 14px;
  margin-bottom: 7px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftContainer {
  width: 50%;
  height: 100%;
  padding-left: 20px;
}
.rightContainer {
  width: 50%;
  height: 100%;
  padding-left: 20px;
}

.text {
  width: 100%;
  display: flex;
  color: lightblue;
  font-size: 14px;
  margin-right: 5px;
}
